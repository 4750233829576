<template>
  <div>
    <!-- <img
      style="
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        display: block;
        width: 100%;
        z-index: -1;
      "
      src="../assets/image/homepage.jpg"
    /> -->
    <headPage />
    <!-- 温度适宜 -->
    <div class="box1" style="overflow: hidden">
      <div class="info" data-aos="fade-right">
        <p class="p1">标准化</p>
        <p class="p2">服务流程</p>
        <div class="line"></div>
      </div>
    </div>
    <div class="box2">
      <!-- 7点 -->
      <div class="dottedLine"></div>
      <div class="circle">
        <div class="content"></div>
        <p>01<br />设计咨询</p>
        <img
          class="img1"
          style="display: block"
          src="../assets/icon/afterSalesServiceIcon/image7.png"
        />
      </div>
      <div class="circle">
        <div class="content"></div>
        <p>02<br />设计建议</p>
        <img
          class="img2"
          style="display: block"
          src="../assets/icon/afterSalesServiceIcon/image9.png"
        />
      </div>
      <div class="circle">
        <div class="content"></div>
        <p>03<br />落地方案</p>
        <img
          class="img3"
          style="display: block"
          src="../assets/icon/afterSalesServiceIcon/image8.png"
        />
      </div>
      <div class="circle">
        <div class="content"></div>
        <p>04<br />深化方案</p>
        <img
          class="img4"
          style="display: block"
          src="../assets/icon/afterSalesServiceIcon/image10.png"
        />
      </div>
      <div class="circle">
        <div class="content"></div>
        <p>05<br />施工执行</p>
        <img
          class="img5"
          style="display: block"
          src="../assets/icon/afterSalesServiceIcon/image11.png"
        />
      </div>
      <div class="circle">
        <div class="content"></div>
        <p>06<br />竣工交付</p>
        <img
          class="img6"
          style="display: block"
          src="../assets/icon/afterSalesServiceIcon/image12.png"
        />
      </div>
      <div class="circle">
        <div class="content"></div>
        <p>07<br />售后维保</p>
        <img
          class="img7"
          style="display: block"
          src="../assets/icon/afterSalesServiceIcon/image13.png"
        />
      </div>
    </div>
    <div class="box3">
      <div class="info3" data-aos="fade-up">
        <p class="title3-1">严把三道关</p>
        <p class="title3-2">优质服务再延申</p>
        <p class="line3"></p>
      </div>
      <div class="info4">
        <div class="bottomnum">01</div>
        <div class="title4-1">产品售后保证</div>
        <div class="bottomnum">02</div>
        <div class="title4-2">过程文件记录</div>
        <div class="bottomnum">03</div>
        <div class="title4-3">组建客户服务中心</div>
      </div>
      <div class="info5">
        <img
          class="info5-1"
          style="display: block"
          src="../assets/image/afterSalesService/image20.png"
        />
        <div class="title5-1">大品牌权威保证</div>
        <div class="title5-2">KNX协议保障</div>
        <div class="title5-3">
          所用产品均为国内外知名品牌，从产品研发、品质制造上严控把控。
        </div>
        <div class="title5-4">
          MH享家加入了KNX国际协议标准。KNX，是一个开放式的国际标准，有着系统稳定、兼容互通、组网便捷，以及应用领域广泛等优势。
        </div>
        <img
          class="info5-2"
          style="display: block"
          src="../assets/image/afterSalesService/image21.png"
        />
      </div>
    </div>
    <div class="box4">
      <div class="bgc"></div>
      <img
        class="bgc2"
        style="display: block"
        src="../assets/image/afterSalesService/image16.png"
      />
      <div class="title6-1">云端文件交付</div>
      <div class="title6-2">
        在阿里云平台开设客户专属账号，将项目过程中涉及到的机电设计施工图纸、现场照片等相关文件资料,统一上传交付。
      </div>
      <div class="picsright">
        <img
          class="bgc3"
          style="display: block"
          src="../assets/image/afterSalesService/image15.jpg"
        />
        <img
          class="bgc4"
          style="display: block"
          src="../assets/image/afterSalesService/image14.jpg"
        />
      </div>
      <div class="bgc5">
        <p class="bgc5-1">其中包括：</p>
        <p class="bgc5-2">隐蔽工程720°全景记录</p>
        <p class="bgc5-3">
          管道水电的布局走向存档,720度无死角记录每个空间的隐蔽工程,后期维修
        </p>
        <img
          class="bgc5-4"
          style="display: block"
          src="../assets/image/afterSalesService/image17.png"
        />
      </div>
    </div>
    <div class="box5">
      <img
        class="info6"
        style="display: block"
        src="../assets/image/afterSalesService/image22.png"
      />
      <img
        class="info7"
        style="display: block"
        src="../assets/image/afterSalesService/image18.jpg"
      />
      <div class="title7-1">售后服务协议</div>
      <div class="title7-2">客户服务中心</div>
      <div class="title7-3">
        签署售后维修合作协议，交付后继续为客户提供更优质全面的服务，提升服务质量。
      </div>
      <div class="title7-4">
        专业售后团队服务，24小时及时响应，1年质保，3年维保。
      </div>
    </div>
    <footPage />
  </div>
</template>
<script>
import headPage from "@/components/head.vue";
import footPage from "@/components/foot.vue";
export default {
  methods: {
    jump(url) {
      this.$router.push(url);
    },
  },
  components: {
    headPage,
    footPage,
  },
};
</script>

<style lang="scss" scoped>
.box5 {
  position: absolute;
  top: 2966px;
  height: 694px;
  width: 100%;
  overflow: hidden;
}
.info6 {
  width: 608px;
  display: block;
  margin-left: 144px;
}
.info6:hover {
  transform: scale(1.2);
  box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
}
.info7 {
  margin-left: 778px;
  width: 603px;
  margin-top: -380px;
}
.info7:hover {
  transform: scale(1.2);
  box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
}
.bgc5-1 {
  font-family: "SourceHanSansCN-Medium";
  color: #000;
  font-size: 16px;
  margin-left: 45px;
  margin-top: 29px;
}
.bgc5-2 {
  font-family: "SourceHanSansCN-Medium";
  color: #d1a937;
  font-size: 26px;
  width: 265px;
  margin-left: 45px;
  margin-top: 0px;
}
.bgc5-3 {
  font-family: "SourceHanSansCN-Light";
  font-size: 16px;
  color: #767374;
  width: 317px;
  margin-left: 45px;
  margin-top: 12px;
}
.bgc5-4 {
  width: 140px;
  position: absolute;
  top: 24px;
  left: 404px;
}
.bgc {
  position: absolute;
  top: 2240px;
  height: 290px;
  width: 1243px;
  left: 140px;
  background: linear-gradient(133deg, #efe3b9 10%, #d1a937 77%);
}
.bgc2 {
  position: absolute;
  width: 350px;
  top: 2371px;
  left: 190px;
}
.bgc3 {
  position: absolute;
  width: 285px;
  top: 2259px;
  left: 1031px;
}
.bgc3:hover {
  transform: scale(1.2);
  box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
}
.bgc4 {
  position: absolute;
  width: 297px;
  top: 2282px;
  left: 818px;
  z-index: 999;
}
.bgc4:hover {
  transform: scale(1.2);
  box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
}
.bgc5 {
  position: absolute;
  top: 2541px;
  height: 184px;
  width: 602px;
  right: 135px;
  background-color: #fff;
  text-align: left;
}
.title5-1 {
  font-family: "SourceHanSansCN-Medium";
  color: #000;
  position: absolute;
  bottom: 1498px;
  font-size: 28px;
  left: 136px;
}
.title7-1 {
  font-family: "SourceHanSansCN-Medium";
  color: #000;
  font-size: 28px;
  text-align: left;
  margin-top: 37px;
  margin-left: 140px;
}
.title7-2 {
  font-family: "SourceHanSansCN-Medium";
  color: #000;
  font-size: 28px;
  text-align: left;
  margin-top: -43px;
  margin-left: 779px;
}
.title6-1 {
  font-family: "SourceHanSansCN-Medium";
  color: #000;
  position: absolute;
  font-size: 28px;
  top: 2295px;
  left: 250px;
}
.title6-2 {
  font-family: "SourceHanSansCN-Light";
  color: #807e7f;
  position: absolute;
  font-size: 16px;
  text-align: left;
  top: 2349px;
  width: 431px;
  left: 249px;
}
.title5-2 {
  font-family: "SourceHanSansCN-Medium";
  color: #000;
  position: absolute;
  bottom: 1498px;
  font-size: 28px;
  left: 782px;
}
.title5-3 {
  font-family: "SourceHanSansCN-Light";
  color: #807e7f;
  position: absolute;
  font-size: 16px;
  width: 335px;
  text-align: left;
  left: 136px;
  bottom: 1437px;
}
.title7-3 {
  font-family: "SourceHanSansCN-Light";
  color: #807e7f;
  font-size: 16px;
  margin-left: 138px;
  width: 433px;
  margin-top: 16px;
  text-align: left;
}
.title7-4 {
  font-family: "SourceHanSansCN-Light";
  color: #807e7f;
  font-size: 16px;
  width: 433px;
  margin-left: 779px;
  margin-top: -49px;
  text-align: left;
}
.title5-4 {
  font-family: "SourceHanSansCN-Light";
  color: #807e7f;
  position: absolute;
  font-size: 16px;
  width: 404px;
  text-align: left;
  left: 783px;
  bottom: 1412px;
}
.info4 {
  position: relative;
  text-align: left;
}
.info5 {
  position: relative;
}
.info5-1 {
  position: absolute;
  bottom: 1593px;
  width: 593px;
  left: 139px;
}
.info5-1:hover {
  transform: scale(1.2);
  box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
}
.info5-2 {
  display: block;
  position: absolute;
  bottom: 1593px;
  width: 593px;
  left: 783px;
}
.info5-2:hover {
  transform: scale(1.2);
  box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
}
.title4-1 {
  font-family: "SourceHanSansCN-Medium";
  color: #000;
  font-size: 28px;
  top: 59px;
  position: absolute;
  left: 138px;
}
.title4-2 {
  font-family: "SourceHanSansCN-Medium";
  color: #000;
  font-size: 28px;
  top: 751px;
  position: absolute;
  left: 138px;
}
.title4-3 {
  font-family: "SourceHanSansCN-Medium";
  color: #000;
  font-size: 28px;
  top: 1442px;
  position: absolute;
  left: 138px;
}
.title3-1 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 63px;
  color: #d0ac32;
  width: auto;
  font-size: 50px;
  margin-left: 0%;
  margin-top: 128px;
}
.title3-2 {
  font-family: "SourceHanSansCN-Blod";
  font-size: 50px;
  color: #000;
  width: auto;
  margin-left: 0%;
  margin-top: 2px;
}
.line3 {
  width: 61px;
  height: 2px;
  background-color: #000;
  margin-left: 48%;
  margin-top: 24px;
}
.img1 {
  position: absolute;
  width: 50px;
  top: 105px;
  left: 172px;
}
.img2 {
  position: absolute;
  width: 50px;
  top: 105px;
  left: 360px;
}
.img3 {
  position: absolute;
  width: 50px;
  top: 105px;
  left: 547px;
}
.img4 {
  display: block;
  position: absolute;
  width: 50px;
  top: 105px;
  left: 734px;
}
.img5 {
  display: block;
  position: absolute;
  width: 50px;
  top: 105px;
  left: 922px;
}
.img6 {
  position: absolute;
  width: 50px;
  top: 105px;
  left: 1109px;
}
.img7 {
  position: absolute;
  width: 50px;
  top: 105px;
  left: 1298px;
}
.circle {
  border-radius: 50%;
  background: linear-gradient(146deg, #fff -33%, #d0ac32 256%);
  height: 120px;
  width: 120px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 72px;
  z-index: 999;
}

.content {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: linear-gradient(133deg, #efe3b9 10%, #d1a937 77%);
}
.dottedLine {
  background-image: linear-gradient(
    to right,
    #a6a6a6 46%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 18px 1px;
  background-repeat: repeat-x;
  position: absolute;
  width: 1179px;
  height: 7px;
  top: 126px;
  left: 166px;
}
.box1 {
  background-image: url(../assets/image/afterSalesService/image1.png);
  background-size: cover;
  width: 100%;
  height: 522px;
}
.info {
  text-align: left;
  margin-top: 147px;
  margin-left: 132px;
}
.p1 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 63px;
  color: #d0ac32;
  width: 500px;
}
.p2 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 65px;
  color: #fff;
  margin-top: -13px;
  width: 500px;
}
.line {
  background-color: #fff;
  margin-top: 41px;
  width: 59px;
  height: 1px;
  margin-left: -29px;
}
.box2 {
  background-color: #fff;
  width: 100%;
  height: 432px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 139px;
  box-sizing: border-box;
}
p {
  font-family: "SourceHanSansCN-Medium";
  color: #000;
  font-size: 29px;
  width: 134px;
  margin-top: 82px;
  margin-left: -32px;
}
.box3 {
  width: 100%;
  overflow: hidden;
  height: 2609px;
  background-color: #f2f2f2;
}
.bottomnum {
  font-family: "SourceHanSansCN-Medium";
  color: #fff;
  text-align: left;
  font-size: 93px;
  margin-left: 144px;
  margin-top: 43px;
  margin-bottom: 551px;
}

@media (max-width: 500px) {
  .info6 {
    margin-top: 150px;
    overflow: hidden;
  }
  .info7 {
    overflow: hidden;
    height: 340px;
    margin-top: -340px;
  }
  .bgc5 {
    height: 220px;
    right: 155px;
  }
  .img3 {
    left: 555px;
  }
  .img4 {
    left: 745px;
  }
  .img5 {
    left: 935px;
  }
  .img6 {
    left: 1125px;
  }
  .img7 {
    left: 1312px;
  }
}
</style>